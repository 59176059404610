<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady">
      <div v-if="linkEditIndex!=null">
        <div v-for="i in [$v.form.links.$each.$iter[linkEditIndex]]">
          <div v-if="i">
            <div class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">类型</span>
                </div>
                <select class="form-control" id="Type" v-model:value="i.$model.type" @change="linkChangeType(linkEditIndex)">
                  <option value="Tel">电话</option>
                  <option value="Url">链接</option>
                  <option value="Image">二维码</option>
                  <option value="Location">定位</option>
                  <option value="Navigation">导航</option>
                  <!--<option value="Email">邮件</option>-->
                </select>
              </div>
              <span class="text-danger"></span>
            </div>
            <div class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">名称</span>
                </div>
                <input type="text" class="form-control" placeholder="名称" maxlength="4" :class="{ 'is-invalid': i.title.$error }" v-model.trim:value="i.title.$model" />
              </div>
              <span class="text-danger"></span>
              <span class="text-danger" v-if="!i.title.required">请输入名称</span>
              <span class="text-danger" v-if="!i.title.minLength">名称至少 {{i.title.$params.minLength.min}} 个字符</span>
              <span class="text-danger" v-if="!i.title.maxLength">名称最多 {{i.title.$params.maxLength.max}} 个字符</span>
            </div>
            <div v-if="i.$model.type=='Url'||i.$model.type=='Email'||i.$model.type=='Tel'" class="form-group">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="font-weight:700">链接</span>
                </div>
                <input v-if="i.$model.type=='Url'" type="url" class="form-control" placeholder="链接地址" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
                <!--<template v-if="$inRole('管理员')||$inRole('Vip')">
                </template>
                <template v-else>
                    <input v-if="i.$model.type==0" class="form-control" :class="{ 'is-invalid': i.link.$error }" value="需要开通 VIP" disabled />
                </template>-->
                <input v-if="i.$model.type=='Email'" type="email" class="form-control" placeholder="邮件地址" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
                <input v-if="i.$model.type=='Tel'" type="tel" class="form-control" placeholder="电话号码" :class="{ 'is-invalid': i.link.$error }" v-model.trim:value="i.link.$model" />
              </div>
              <span class="text-danger"></span>
              <!--<span class="text-danger" v-if="!i.link.required">请输入内容</span>-->
              <span class="text-danger" v-if="!i.link.minLength">至少 {{i.link.$params.minLength.min}} 个字符</span>
              <span class="text-danger" v-else-if="!i.link.maxLength">最多 {{i.link.$params.maxLength.max}} 个字符</span>
              <span class="text-danger" v-else-if="!i.link.isLink">格式不正确</span>
            </div>
            <div v-else-if="i.$model.type=='Location'" class="form-group">
              <button class="btn btn-sm btn-primary" @click="$refs.linkLocationSelected.showModal()">选择地点</button>
              <div v-if="i.$model.gpsLng!=0||i.$model.gpsLat!=0">
                <MapView :mark="{lat:i.$model.gpsLat,lng:i.$model.gpsLng}"></MapView>
              </div>
              <!--<div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                      <span class="input-group-text" style="font-weight:700">lat</span>
                  </div>
                  <input type="number" class="form-control" placeholder="链接地址" :class="{ 'is-invalid': i.gps.$error }" @change="i.$touch()" v-model.trim:value="i.$model.gpsLat" />
              </div>
              <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                      <span class="input-group-text" style="font-weight:700">lng</span>
                  </div>
                  <input type="number" class="form-control" placeholder="链接地址" :class="{ 'is-invalid': i.gps.$error }" v-model.trim:value="i.$model.gpsLng" />
              </div>-->
              <div class="text-danger" v-if="!i.gps.isGps">请选择地点</div>
            </div>
            <div v-else-if="i.$model.type=='Navigation'" class="form-group">
            </div>
            <div v-else-if="i.$model.type=='Image'" class="form-group">
              <label for="">二维码<span v-if="!form.isPro" style="color:orange" @click.prevent="$emit('proTips')">（Pro 功能<i class="fas fa-question-circle"></i>）</span></label>
              <div class="">
                <div v-if="i.$model.image">
                  <div class="card-tools float-right">
                    <button type="button" class="btn btn-box-tool" @click="i.$model.image=null" title="删除"><i class="fas fa-trash"></i></button>
                  </div>

                  <!--{{i.$model.image.url}}-->
                  <a>
                    <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + i.$model.image.url + ')'}"></div>
                  </a>
                </div>
                <div v-else>
                  <button type="button" class="btn btn-sm btn-danger mr-2" style="pointer-events:auto" @click.prevent="showImageLibDialog()">选择图片</button>
                </div>
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap">
            <button type="button" class="btn btn-sm btn-danger mr-2" style="pointer-events:auto" @click.prevent="deleteLink(linkEditIndex)">删除</button>
            <button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="modelHide" :disabled="i.link.$invalid">确定</button>
          </div>
        </div>
      </div>
    </component>
    <template v-if="urls">
      <MapSelectedPointModal ref="linkLocationSelected" :markerPoint="{lat: (form.links[linkEditIndex]||{}).gpsLat,lng:(form.links[linkEditIndex]||{}).gpsLng}" @submit="setLinkLocation">
      </MapSelectedPointModal>
      <FileLib ref="ImageLib" withType="Image" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" :params="params" @submit="imageLibSelected"></FileLib>
    </template>
  </div>
</template>
<script>
  import MapView from '../../Map/MapView'
  import MapSelectedPointModal from '../../Map/MapSelectedPointModal-Leaflet'
  import FileLib from '../../FileLib'
  export default {
    components: {
      MapView,
      MapSelectedPointModal,
      FileLib
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      linkEditIndex() {
        return this.publicData.linkEditIndex
      },
      form() {
        return this.publicData.form || { links: [] }
      },
      $v() {
        return this.publicData.$v
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          linkShowView: {
            target: 'linkView', name: 'linkShowView',
          }
        },
        funcs: {
          show: this.show
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      deleteLink(linkIndex) {
        this.modelHide()
        this.$nextTick(() => {
          this.getFunc('changePublicData')({ linkEditIndex: null })
          this.form.links.splice(linkIndex, 1)
        })
      },
      setLinkLocation(p) {
        console.log(p)
        this.form.links[this.linkEditIndex].gpsLat = p.lat
        this.form.links[this.linkEditIndex].gpsLng = p.lng
      },
      linkChangeType(index) {
        console.log('linkChangeType', index)
        const d = { 'Url': '我的网站', 'Email': '我的邮箱', 'Tel': '我的电话', 'Image': '二维码', 'Location': '当前位置', 'Navigation': '导航到此' }
        var v = false
        for (var i in d) {
          if (this.form.links[index].title == d[i]) {
            v = true
            break
          }
        }
        if (v) {
          this.form.links[index].title = d[this.form.links[index].type]
        }
      },
      showImageLibDialog() {
        this.$refs.ImageLib.showDialog();
      },
      imageLibSelected(image) {
        this.form.links[this.linkEditIndex].image = image
        //this.v.$touch()
      },
    },
  }
</script>
<style scoped>
  .links {
    list-style: none;
    font-size: 0.8rem;
    margin: 0;
    opacity: .6
  }

    .links li {
      display: inline-block;
      margin: 0 5px;
    }

      .links li i {
        display: block;
      }

      .links li a {
        text-align: center;
        color: #fff
      }
</style>
